var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:""},[_c('titleBar',{attrs:{"title":"Explore","back":false,"showBackArrowDesktop":true,"subtitle":"","centered":false,"theme":"white","showSignup":false,"containerClass":"donotuse","showDesktop":true,"showAfterScroll":0,"scrollTopOnClick":true,"inline":false,"actionButtonStyle":"small","actionButtonRoundBg":"transparent","iconPack":"far","isHome":false,"actions":[
      {
        icon: 'search',
        to: '/explore',
        hidden: false,
        hiddenTablet: false,
      },
      {
        icon: 'bell',
        to: '/notifications',
        hidden: false,
        hiddenTablet: false,
      },
      /*  {
        icon: 'comment',
        to: '/messages',
        hidden: false,
        hiddenTablet: false,
      },*/
    ]}}),_vm._v(" "),(_vm.$store.main.getters.hasPages)?_c('div',{staticClass:"section"},[_c('createTabs',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}]})],1):_vm._e(),_vm._v(" "),_c('h3',{staticClass:"title is-5"},[_vm._v("Pick an image generator")]),_vm._v(" "),_c('BlueprintList',{attrs:{"items":_vm.suggestedBlueprints}}),_vm._v(" "),_c('hr'),_vm._v(" "),_c('hr'),_vm._v(" "),_c('hr'),_vm._v(" "),_c('hr'),_vm._v(" "),_c('hr'),_vm._v("\n  List popular templates...\n  "),_c('hr'),_vm._v(" "),_c('hr'),_vm._v(" "),_c('hr'),_vm._v(" "),_c('hr'),_vm._v(" "),_c('FeaturedBots'),_vm._v(" "),_c('div',{staticClass:"section"},[(!_vm.loadingProj)?_c('div',{staticClass:"container home"}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }