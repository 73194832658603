<template>
  <div class=" ">
    <titleBar
      title="Explore"
      :back="false"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="far"
      :isHome="false"
      :actions="[
        {
          icon: 'search',
          to: '/explore',
          hidden: false,
          hiddenTablet: false,
        },
        {
          icon: 'bell',
          to: '/notifications',
          hidden: false,
          hiddenTablet: false,
        },
        /*  {
          icon: 'comment',
          to: '/messages',
          hidden: false,
          hiddenTablet: false,
        },*/
      ]"
    />

    <div class="section" v-if="$store.main.getters.hasPages">
      <createTabs v-show="true" />
    </div>

    <h3 class="title is-5">Pick an image generator</h3>

    <BlueprintList :items="suggestedBlueprints" />
    <hr />
    <hr />
    <hr />
    <hr />

    <hr />
    List popular templates...
    <hr />
    <hr />
    <hr />
    <hr />
    <FeaturedBots />
    <div class="section">
      <div class="container home" v-if="!loadingProj">
        <!-- 
        <div class="columns is-multiline">
          <div class="column is-3-desktop is-12-tablet">
            <h1 class="title is-3">Your APIs</h1>
            <h1 class="subtitle is-5">Plan, prototype and manage API projects.</h1>
          

            <router-link :to="{ path: $store.main.state.userToken ? '/new' : '/login' }" active-class="is-active" exact>
              <b-button rounded>Add a new API</b-button>
            </router-link>
          </div>
          <div class="column">
            <div class="columns is-multiline">
              <div class="column is-6-tablet is-6-desktop is-4" v-for="i in projs" :key="i.id">
              
                <router-link :to="{ path: '/' + i.id }" active-class="is-active" exact>
                  <projCard :proj="i" />
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <router-link :to="{ path: '/explore' }">
          <b-button type="is-info is-outlined">Explore Public examples</b-button>
        </router-link>-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import FeaturedBots from "@/components/FeaturedBots.vue";
import botsTabs from "@/components/nav/botsTabs.vue";
import createTabs from "@/components/nav/createTabs.vue";
import BlueprintList from "@/components/BlueprintList.vue";

export default {
  name: "Home",
  components: {
    FeaturedBots,
    // botsTabs,
    createTabs,
    BlueprintList,
    //  HelloWorld,
    //   projCard,
  },
  data() {
    return {
      suggestedBlueprints: [],
      projs: [],
      loading: true,
      publi: [],

      // loadingOps: true,
    };
  },
  methods: {
    async performSearch() {
      this.isLoading = true;
      var q = this.search;
      //  alert(3);
      // perform your search here, then update results and isLoading accordingly
      // the following is a placeholder and should be replaced with your own search code

      //redirect to search page
      //withj ?q=serch

      this.$router.push({ path: "/search", query: { q: q } });
    },
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.publi = proj;
      this.loadingProjPubli = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/

    //  this.projs = proj;
    this.loading = false;

    var u = this.$store.main.state.userId;
    window.API.getSuggestedBlueprints({ type: "image" }).then((proj) => {
      this.suggestedBlueprints = proj;
      this.loading = false;
      // console.log("PROJJ!", proj, proj.id);
    });
    /*
    window.API.getProjectOperations(this.$route.params.project).then((ops) => {
      var opId = this.$route.params.operation;
      this.ops = ops;
      this.loadingOps = false;
      this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
    });*/
  },
};
</script>
<style scoped>
.logo_search_home {
  width: 50vw;
  max-width: 300px;
  margin: auto;
  display: block;
  margin-bottom: 30px;
}

.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 98vh;
  min-height: 300px;
}

/* on mobile, container is only 80% of the screen */
@media (max-width: 1024px) {
  .search-container {
    height: 80vh;
  }
}

.search-form {
  max-width: 700px;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-form img {
  display: block;
}

.arrow {
  position: absolute;
  bottom: 0;
  /* assuming you want the arrow centered horizontally */
  left: 50%;
  transform: translateX(-50%);
}

.search-input {
  width: 80vw;
  max-width: 600px;
}
</style>
