<template>
  <div>
    <div v-if="layout == 'grid'">
      <div class="profileCard grid">
        <router-link :to="prefix + item.url" v-if="includeLinks">
          <img :src="item.image" loading="lazy" class="avatar" style="" />
          <header class=" ">
            <h4 class="title is-4">
              {{ item.name || item.bot }}
            </h4>
          </header>
          <p v-if="!noDescription" class="desc elep2 regular-text" v-html="item.desc"></p>
        </router-link>
        <div v-else>
          <img :src="item.avatar" loading="lazy" class="avatar" style="" />
          <header class=" ">
            <h4 class="title is-4">
              {{ item.handle }}
            </h4>
          </header>
          <p v-if="!noDescription" class="desc elep2 regular-text" v-html="item.desc"></p>
        </div>
      </div>
    </div>

    <div v-if="layout == 'image'" class="photo hoverable">
      <div class=" ">
        <router-link :to="prefix + item.url" v-if="includeLinks">
          <div class="card" :class="'card' + index">
            <div class="card-image">
              <figure class="image is-5by4">
                <img :src="item.image || item.avatar" alt="" style="background: #f1f1f1" />
              </figure>
            </div>
            <p class="byline">
              <img
                :src="item.image || item.avatar"
                alt=""
                class="avatar"
                style="width: 12px; height: 12px; margin-right: 3px"
              />
              <small> {{ item.bot }}</small>
            </p>
            <div class="card-content">
              <div class="media">
                <!--  
                <div class="media-left">
                  <figure class="image is-48x48">
                    <img :src="item.avatar" class="avatar" alt="" />
                  </figure>
                </div>-->
                <div class="media-content">
                  <p class="title is-5">{{ item.label || item.name }}</p>
                  <p class="subtitle is-6 elep3 cat">
                    {{ item.desc }}

                    <span class="  " v-if="item.nbInteractions">
                      •
                      <small class="heartCount">
                        <!-- icon -->
                        <i class="fas fa-heart"></i>
                        {{ item.nbInteractions }}
                      </small>
                    </span>
                  </p>
                </div>
              </div>

              <div class="content elep2 regular-text" v-if="!noDescription">
                {{ item.bio }}
                <!-- 
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec iaculis mauris. <a>@bulmaio</a>.
                <a href="#">#css</a> <a href="#">#responsive</a>
                <br />
                <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time> -->
              </div>
            </div>
          </div>
        </router-link>
        <!--   
        <router-link :to="item.url" v-if="includeLinks">
          <img :src="item.cover || item.avatar" loading="lazy" class="avatar" style="" />
          <header class=" ">
            <h4 class="title is-4">
              {{ item.handle }}
            </h4>
          </header>
          <p class="desc elep2" v-html="item.bio"></p>
        </router-link>
        <div v-else>
          <img :src="item.avatar" loading="lazy" class="avatar" style="" />
          <header class=" ">
            <h4 class="title is-4">
              {{ item.handle }}
            </h4>
          </header>
          <p class="desc elep2" v-html="item.bio"></p>
        </div>

-->
      </div>
    </div>

    <div v-if="layout == 'cardManage'" class="card">
      <div class="card-content">
        <div class="media">
          <div class="media-left">
            <router-link :to="item.url">
              <figure class="image is-64x64">
                <img :src="item.avatar" alt="" class="avatar" style="height: 64px" />
              </figure>
            </router-link>
          </div>
          <div class="media-content">
            <router-link :to="item.url">
              <p class="title is-4 nowrap">{{ item.handle }}</p>
            </router-link>
            <p class="subtitle is-6">
              <b-tag rounded type="  is-light is-success" v-if="item.visibilityMode == 'public'">
                <span> <span style="color: rgb(85, 255, 0)"> ● </span> Online</span>

                <!--  
                {{ item.visibilityMode == "public" }} Free plan-->
              </b-tag>
              <b-tag rounded type="  is-light is-dangerNO" v-else>
                <span> Private bot </span>

                <!--  
                   <b-icon icon="fal fa-lock"></b-icon>
                {{ item.visibilityMode == "public" }} Free plan-->
              </b-tag>
              <span type="i  is-light" style="font-size: 12px" v-if="item.nbInteractions">
                {{ item.nbInteractions }} users

                {{ item.trafficStr }}</span
              >
            </p>
          </div>
          <div class="media-right">
            <DotDrop
              size="small"
              :items="[
                //  { t: 'New post', icon: 'far fa-plus', to: '/' + bot?.handle + '/new' },
                { t: 'Settings', icon: 'far fa-cog', to: '/bots/' + item.handle },

                //  { t: 'Insights', icon: 'far fa-analytics', to: item.url + '/manage/insights' },
                { t: 'Copy link', icon: 'far fa-link', to: '/' + bot?.handle + '/new' },
                { t: 'Hub page', icon: 'far fa-cog', to: '/bots/me' + item.url + '' },
                // { t: 'Remove', icon: 'far fa-trash', to: item.url + '/manage/remove' },
                //{ t: 'Download CSV', icon: 'far fa-download', click: exportAsCsv },
              ]"
            />
          </div>
        </div>
        <div class="content">
          <!--  
          <p class=" ">{{ item.category }}</p>
          -->
          <div class="button-row-equal">
            <b-button tag="router-link" :to="'/bots/' + item.handle" type="is-dark is-outlined">Settings</b-button>
            <b-button tag="router-link" :to="item.url" type="is-dark">View</b-button>

            <!--  
                
        <router-link :to="prefix + item.url">
              <b-button type="is-primary outlined">Configure</b-button>
            </router-link>
            <router-link :to="item.url">
              <b-button type="is-primary is-light">View</b-button>
            </router-link>
            <router-link to="/path1">
              <b-button type="is-primary">Primary</b-button>
            </router-link>-->
          </div>
        </div>
        <!-- 🚧
        <div class="content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec
          iaculis mauris. <a>@bulmaio</a>. <a href="#">#css</a>
          <a href="#">#responsive</a>
          <br />
          <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
        </div> -->
      </div>
    </div>

    <div v-if="layout == 'card'" class="card-lay hoverable">
      <router-link :to="prefix + item.url" v-if="includeLinks">
        <div class="card" :class="'card' + index">
          <!-- 
          <div class="card-image">
            <figure class="image is-3by2">
              <img :src="item.cover || item.avatar" alt="" style="background: #f1f1f1" />
            </figure>
          </div> -->
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img :src="item.avatar" class="avatar" alt="" />
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-5 elep1">{{ item.handle }}</p>
                <p class="subtitle is-6 cat">{{ bot?.category }}</p>
              </div>
            </div>

            <div class="content elep2 regular-text" v-if="!noDescription">
              {{ item.bio }}
              <!-- 
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec iaculis mauris. <a>@bulmaio</a>.
                <a href="#">#css</a> <a href="#">#responsive</a>
                <br />
                <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time> -->
            </div>

            <div class="content heartCount" v-if="item.nbInteractions">
              <small style="">
                <!-- icon -->
                <i class="fas fa-heart"></i>
                {{ item.nbInteractions }}
              </small>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import DotDrop from "@/components/e/DotDrop.vue";

export default {
  components: {
    DotDrop,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    // vueJsonEditor,
    // VueExcelEditor,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    includeLinks: {
      type: Boolean,
      default: true,
    },
    layout: {
      type: String,
      default: "grid",
    },
    index: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
      default: "",
    },
    noDescription: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.photo .byline {
  margin-top: 1px;
  margin-left: 5px;
}

.photo .card-content {
  padding-top: 6px;
  padding-bottom: 6px;
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.regular-text {
  color: #333;
}
.profileCard {
  text-align: center !important;
}

.heartCount {
  color: #ffbfc5;
}

.cat {
  opacity: 0.5;
  text-transform: capitalize;
}

.card-lay .card {
  margin-bottom: 20px;
  margin-right: 20px;
}

.card-lay .avatar {
  width: 48px;
  height: 48px;
}

.minilist {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
}
.minilist:hover {
  background: #eee;
}
.minilist .avatar {
  width: 22px;
  height: 22px;
  margin-right: 9px;
}
.card-lay .card-content {
  padding: 20px;
}

.hoverable .card {
  transition: all ease 2.2;
  /*
  position: relative;
  top: 0;
  transition: all ease 0.2;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3D(0, 0, 0);

  backface-visibility: hidden;
  animation-fill-mode: forwards;
  transform-style: preserve-3d;
  display: inline-block;
  height: 100px;
  background-color: red;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  transform-style: preserve-3d;
  */
}
/* desktop only media query  */
@media only screen and (min-width: 769px) {
  .hoverable:hover .card {
    transform: scale(1.01) translateY(-3px);
  }
}

/*
@keyframes move {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translate(0);
  }
}
*/
.photo img {
  object-fit: cover;
}
.card0 .image.is-3by2 {
  padding-top: 45%;
}
.profileCard.card {
  /*
  width: 200px;*/
  background: #ccc;
}
.profileCard .title {
  margin-bottom: 0.2em;
}
.profileCard img {
  width: 140px;
  height: 140px;
  border-radius: 500px;
  border: 1px #888 solid;
  margin-bottom: 10px;
  background: #ffffff;
}
.profileCard p.desc {
  margin-bottom: 30px;
}

.profileCard img {
  width: 110px;
  height: 110px;
}

.profileCard .title {
  font-size: 1.3rem !important;
  font-weight: bold;
}
.profileCard p.desc {
  font-size: 0.9rem !important;
  margin-bottom: 0;
}

.button-row-equal {
  display: flex;
}

.button-row-equal .button:not(:last-child) {
  flex: 1;
  margin-right: 10px;
}

.button-row-equal .button:last-child {
  flex: 1;
}

.button {
  border-radius: 12px;
}
</style>
