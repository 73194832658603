<template>
  <div>
    <div class="columns title-and-nav is-mobile" v-if="title">
      <div class="column">
        <h4 class="title is-4">{{ title }}</h4>
      </div>
    </div>

    <div
      v-if="layout == 'image'"
      class="columns is-mobile is-variable is-1"
      :class="{ 'is-multiline': !nowrap, nowrap: nowrap }"
    >
      <blueprint-card
        v-for="item in visibleItems"
        :key="'fd' + item.handle + item.id"
        :item="item"
        :include-links="includeLinks"
        :prefix="prefix"
        :noDescription="noDescription"
        :layout="layout"
        class="column is-6-mobile is-4-tablet is-4-desktop is-3-fullhd"
      ></blueprint-card>
    </div>

    <div v-if="layout == 'grid'" class="columns is-mobile" :class="{ 'is-multiline': !nowrap, nowrap: nowrap }">
      <blueprint-card
        v-for="item in visibleItems"
        :key="'fd' + item.handle + item.id"
        :item="item"
        :include-links="includeLinks"
        :prefix="prefix"
        :noDescription="noDescription"
        :layout="layout"
        class="column is-6-mobile is-4-tablet is-4-desktop is-3-fullhd"
      ></blueprint-card>
    </div>

    <div v-if="layout == 'minilist'" class="minilist">
      <blueprint-card
        v-for="item in visibleItems"
        :key="item.handle"
        :item="item"
        :include-links="includeLinks"
        :prefix="prefix"
        :noDescription="noDescription"
        :layout="layout"
        class="minilist-item"
      ></blueprint-card>
    </div>

    <div v-if="layout == 'cardManage'" class="columns is-multiline is-mobile">
      <blueprint-card
        v-for="item in visibleItems"
        :layout="layout"
        :key="item.handle"
        :item="item"
        :include-links="includeLinks"
        :prefix="prefix"
        :noDescription="noDescription"
        class="column is-12-mobile is-6-tablet is-6-desktop is-4-fullhd"
      ></blueprint-card>
    </div>
  </div>
</template>

<script>
import BlueprintCard from "./BlueprintCard.vue";
import _ from "lodash";

export default {
  components: {
    BlueprintCard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    includeLinks: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "image",
    },
    prefix: {
      type: String,
      default: "",
    },
    noDescription: {
      type: Boolean,
      default: false,
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  computed: {
    visibleItems() {
      let items = this.items;
      items = _.compact(items);
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return items.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.items.length / this.pageSize);
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    goToPage(page) {
      this.currentPage = page;
    },
  },
};
</script>

<style scoped>
.title-and-nav,
.title-and-nav .column {
  padding-bottom: 0;
  align-items: flex-end;
}

.nowrap {
  overflow: hidden;
}
</style>
